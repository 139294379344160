<template>
  <div class="account_management">
    <div class="top_card card">
      <div class="storeCenter_item">
        <div class="storeCenter_item_top">
          <!-- 卡片左边 -->
          <div class="storeCenter_item_top_left">
            <div class="banner_box">
              <img :src="topDetails.surfaceDrawing" alt="" />
              <!-- v-if="item.activityImg" -->
              <!-- <img v-else src="../../assets/image/Group 3237.png" alt="" /> -->
            </div>
            <div class="storeCenter_item_top_left_flex">
              <div class="title_row">
                <div class="title">
                  {{ topDetails.activityName }}
                </div>
                <div class="title_row_item">
                  <img
                    v-if="topDetails.participationMode === '1'"
                    :src="require('@/assets/image/on-line.png')"
                    alt=""
                  />
                  <img v-else :src="require('@/assets/image/off-line.png')" alt="" />
                  <span class="mode_txt">{{
                    topDetails.participationMode === '1' ? '线上' : '线下'
                  }}</span>
                </div>
                <div class="state_box" :class="getActivityState(topDetails, 'class')">
                  <img
                    v-if="topDetails.activityState === '4'"
                    :src="require('@/assets/image/activity-state01.png')"
                    alt=""
                  />
                  <span>{{ getActivityState(topDetails, 'txt') }}</span>
                </div>
              </div>
              <!-- 活动主题 -->
              <div class="center_row">{{ topDetails.caseSubject }}</div>
              <div class="address">
                <div>
                  <span>所在地区：</span>
                  <span v-if="topDetails.activityProvince">
                    {{ topDetails.activityProvince }}
                    <span>-</span>
                    <span>{{ topDetails.activityCity ? topDetails.activityCity : '暂无' }}</span>
                  </span>
                  <span v-else>暂无</span>
                </div>
                <div v-if="topDetails.activityPhase === '1'">
                  <div v-if="topDetails.activityStartTime">
                    <span>活动时间：</span>
                    <span> {{ topDetails.activityStartTime }}</span>
                    <span> 至 </span>
                    <span>{{ topDetails.activityEndTime }}</span>
                  </div>
                  <div v-else>
                    <span>活动时间：暂无</span>
                  </div>
                </div>
                <div v-else>
                  <span>活动时间：</span>
                  <span>
                    {{
                      topDetails.activityProjectTime ? topDetails.activityProjectTime : '暂无'
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body_card card">
      <el-tabs v-model="queryInfo.type" @tab-click="tabClick">
        <el-tab-pane label="报名成功" name="2">
          <div class="search">
            <div>
              <el-button type="primary" @click="clickVisibles">新增人员</el-button>
              <el-button @click="exportExcel" plain>导出名单</el-button>
            </div>
            <div class="search_flex">
              <el-form ref="form" label-width="100px">
                <div style="display: flex">
                  <el-form-item label="用户类型:" prop="">
                    <el-radio-group v-model="queryInfo.userLevel" @change="search">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button label="0">普通用户</el-radio-button>
                      <el-radio-button label="1">秘书长</el-radio-button>
                      <el-radio-button label="2">会员用户</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="签到状态:" prop="">
                    <el-radio-group v-model="queryInfo.registerStatus" @change="search">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button label="1">已签到</el-radio-button>
                      <el-radio-button label="0">未签到</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <div class="search_right">
                      <el-input
                        placeholder="请输入姓名、商会名称"
                        v-model="queryInfo.queryConditions"
                        class="input-with-select"
                        clearable
                      >
                        <el-button slot="append" @click="search">搜索</el-button>
                      </el-input>
                    </div>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
          <el-table
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            class="table_data"
            :data="tableData"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            ref="multipleTable"
            style="width: 100%"
            tooltip-effect="dark"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
            @sort-change="changeSort"
          >
            <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
            <el-table-column prop="id" label="编号" align="center" width="80"> </el-table-column>
            <el-table-column prop="realName" label="姓名" align="center" width="80">
            </el-table-column>

            <el-table-column label="性别" align="center" width="60">
              <template v-slot="{ row }">
                <div class="sex" v-if="row.sex == '1'">男</div>
                <div class="sex" v-else>女</div>
              </template>
            </el-table-column>

            <el-table-column prop="phone" label="手机号" width="120" align="center">
            </el-table-column>

            <el-table-column prop="companyFullName" label="商会名称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="userLevel" label="用户类型" align="center">
              <template v-slot="{ row }">
                <span v-if="row.userLevel == 0">普通用户</span>
                <span v-if="row.userLevel == 1">秘书长</span>
                <span v-if="row.userLevel == 2">会员用户</span>
              </template>
            </el-table-column>
            <el-table-column prop="position" label="职务" align="center"> </el-table-column>
            <el-table-column prop="ticketName" label="票种" align="center">
              <template v-slot="{ row }">
                <span>{{ row.ticketName }}</span>
              </template>
            </el-table-column>

            <el-table-column
              width="140"
              prop="purchaseCount"
              label="购买票数(张)"
              align="center"
            ></el-table-column>

            <el-table-column prop="activePrice" label="支付金额" align="center" sortable>
              <template v-slot="{ row }">
                <span>{{ '￥' + row.activePrice }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="updateName" label="审核人" align="center"></el-table-column> -->
            <el-table-column
              prop="createTime"
              label="报名时间"
              width="100"
              align="center"
              sortable
            ></el-table-column>
            <el-table-column prop="companyFullName" label="操作" align="center">
              <template v-slot="{ row }">
                <span
                  style="color: #4e93fb; cursor: pointer"
                  v-if="row.registerStatus == 0 || row.registerStatus == null"
                  @click="userSignIn(row)"
                  >签到</span
                >
                <span style="color: #ff7d18; cursor: pointer" v-if="row.registerStatus == 1"
                  >已签到</span
                >
                <span
                  style="color: #4e93fb; cursor: pointer; margin-left: 10px"
                  @click="dialoginfo(row, 1)"
                  >详情</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="待审核" name="1">
          <div class="search">
            <div class="search_flex">
              <div>用户类型：</div>
              <el-radio-group v-model="queryInfo.userLevel" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="0">普通用户</el-radio-button>
                <el-radio-button label="1">秘书长</el-radio-button>
                <el-radio-button label="2">会员用户</el-radio-button>
              </el-radio-group>
            </div>
            <div class="search_right">
              <el-input
                placeholder="请输入姓名、商会名称"
                v-model="queryInfo.queryConditions"
                class="input-with-select"
                clearable
              >
                <el-button slot="append" @click="search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <el-table
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            class="table_data"
            :data="tableData"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            ref="multipleTable"
            style="width: 100%; height: 50vh"
            tooltip-effect="dark"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
            @sort-change="changeSort"
          >
            <!-- <el-table-column type="selection" width="55" align="center" :reserve-selection="true" /> -->
            <el-table-column prop="id" label="编号" align="center"> </el-table-column>
            <el-table-column prop="realName" label="姓名" align="center"> </el-table-column>

            <el-table-column label="性别" align="center">
              <template v-slot="{ row }">
                <div class="sex" v-if="row.sex == '1'">男</div>
                <div class="sex" v-else>女</div>
              </template>
            </el-table-column>

            <el-table-column prop="phone" label="手机号" width="120" align="center">
            </el-table-column>

            <el-table-column prop="companyFullName" label="商会名称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="userLevel" label="用户类型" align="center">
              <template v-slot="{ row }">
                <span v-if="row.userLevel == 0">普通用户</span>
                <span v-if="row.userLevel == 1">秘书长</span>
                <span v-if="row.userLevel == 2">会员用户</span>
              </template>
            </el-table-column>
            <el-table-column prop="position" label="职务" align="center"> </el-table-column>
            <el-table-column prop="ticketName" label="票种" align="center">
              <template v-slot="{ row }">
                <span>{{ row.ticketName }}</span>
              </template>
            </el-table-column>

            <el-table-column
              width="140"
              prop="purchaseCount"
              label="购买票数(张)"
              align="center"
            ></el-table-column>

            <el-table-column prop="activePrice" label="支付金额" align="center" sortable>
              <template v-slot="{ row }">
                <span>{{ '￥' + row.activePrice }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="updateName" label="审核人" align="center"></el-table-column> -->
            <el-table-column
              prop="updateTime"
              label="报名时间"
              width="100"
              align="center"
              sortable
            ></el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template v-slot="{ row }">
                <div class="btn">
                  <span class="pass" @click="passClick(row)">通过</span>
                  <span class="un_pass" @click="unPassClick(row)">不通过</span>
                  <span
                    style="color: #4e93fb; cursor: pointer; margin-left: 10px"
                    @click="dialoginfo(row, 2)"
                    >详情</span
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已审核" name="3">
          <div class="search">
            <div class="search_flex">
              <div>审核状态：</div>
              <el-radio-group v-model="queryInfo.applyStatus" @change="search">
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in InformationTypes"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
              <div style="margin-left: 20px">用户类型：</div>
              <el-radio-group v-model="queryInfo.userLevel" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="0">普通用户</el-radio-button>
                <el-radio-button label="1">秘书长</el-radio-button>
                <el-radio-button label="2">会员用户</el-radio-button>
              </el-radio-group>
            </div>
            <div class="search_right">
              <el-input
                placeholder="请输入姓名、商会名称"
                v-model="queryInfo.queryConditions"
                class="input-with-select"
                clearable
              >
                <el-button slot="append" @click="search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <el-table
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            class="table_data"
            :data="tableData"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            ref="multipleTable"
            style="width: 100%"
            tooltip-effect="dark"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
            @sort-change="changeSort"
          >
            <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
            <el-table-column prop="id" label="编号" align="center" width="80"> </el-table-column>
            <el-table-column prop="realName" label="姓名" align="center" width="80">
            </el-table-column>

            <el-table-column label="性别" align="center" width="60">
              <template v-slot="{ row }">
                <div class="sex" v-if="row.sex == '1'">男</div>
                <div class="sex" v-else>女</div>
              </template>
            </el-table-column>

            <el-table-column prop="phone" label="手机号" width="120" align="center">
            </el-table-column>

            <el-table-column prop="companyFullName" label="商会名称" align="center">
              <template v-slot="{ row }">
                <span>{{ row.companyFullName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="userLevel" label="用户类型" align="center">
              <template v-slot="{ row }">
                <span v-if="row.userLevel == 0">普通用户</span>
                <span v-if="row.userLevel == 1">秘书长</span>
                <span v-if="row.userLevel == 2">会员用户</span>
              </template>
            </el-table-column>
            <el-table-column prop="position" label="职务" align="center"> </el-table-column>
            <el-table-column prop="ticketName" label="票种" align="center">
              <template v-slot="{ row }">
                <span>{{ row.ticketName }}</span>
              </template>
            </el-table-column>

            <el-table-column
              width="140"
              prop="purchaseCount"
              label="购买票数(张)"
              align="center"
            ></el-table-column>

            <el-table-column prop="activePrice" label="支付金额" align="center" sortable>
              <template v-slot="{ row }">
                <span>{{ '￥' + row.activePrice }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="position" label="审核状态" align="center">
              <template v-slot="{ row }">
                <span v-if="row.applyStatus == 1">待审核</span>
                <span v-if="row.applyStatus == 2">审核通过</span>
                <span v-if="row.applyStatus == 3">拒绝</span>
              </template>
            </el-table-column>

            <el-table-column prop="updateName" label="操作人" align="center"></el-table-column>
            <el-table-column
              prop="applyTime"
              label="操作时间"
              sortable
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center">
              <template v-slot="{ row }">
                <span
                  style="color: #4e93fb; cursor: pointer; margin-left: 10px"
                  @click="dialoginfo(row, 3)"
                >
                  详情
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <!-- 拒绝的理由弹窗 -->
    <el-dialog title="不通过" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="refuse">
        <el-form
          :model="refuseObj"
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-form-item label="理由" prop="refuseReason">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入不通过理由"
              v-model="refuseObj.refuseReason"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增报名人员弹窗 -->
    <el-dialog
      title="新增"
      :visible.sync="accountTypeDialogVisibles"
      width="50%"
      class="account_dialog"
    >
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="accountTypeDialogs">
        <div class="input_width">
          <el-form-item label="姓名" prop="userName">
            <el-input
              class="flex_input"
              v-model="adminUser.userName"
              placeholder="请输入真实姓名"
            />
          </el-form-item>

          <el-form-item label="性别" prop="">
            <el-radio-group v-model="adminUser.gender">
              <el-radio :label="1">先生</el-radio>
              <el-radio :label="2">女士</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              class="flex_input"
              v-model="adminUser.mobile"
              placeholder="请输入手机号"
              @change="phoneChanged"
            />
          </el-form-item>
          <el-form-item label="所在商会" prop="companyFullName">
            <el-autocomplete
              class="flex_input"
              v-model="adminUser.companyFullName"
              :fetch-suggestions="enterpriseNameQuerySearch"
              placeholder="请输入关键词检索商会"
              :trigger-on-focus="false"
              @select="enterpriseNameSelect"
              @input="releaseUnitChanged"
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="商会职位" prop="">
            <el-input
              class="flex_input"
              v-model="adminUser.position"
              placeholder="请输入商会职务"
            />
          </el-form-item>
          <el-form-item label="所在行业" prop="">
            <el-autocomplete
              class="flex_input"
              v-model="adminUser.companyIndustry"
              :fetch-suggestions="industryQuerySearch"
              placeholder="请输入关键词检索商会"
              :trigger-on-focus="false"
              @select="industrySelect"
              @input="industryChanged"
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="用户角色" prop="">
            <el-radio-group v-model="adminUser.type" @change="getTicketPrice($event, 1)">
              <el-radio label="0">普通用户</el-radio>
              <el-radio label="1">秘书长</el-radio>
              <el-radio label="2">会员</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="票种" prop="">
            <el-radio-group v-model="adminUser.priceId" @change="getTicketPrice($event, 2)">
              <el-radio v-for="(item, index) in ticketList" :key="index" :label="item.id">
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="票数" prop="">
            <div class="row">
              <el-input-number
                v-model="adminUser.count"
                :min="1"
                :max="10"
                label="请输入票数"
                @change="getTicketPrice($event, 3)"
                disabled
              ></el-input-number>
              <span style="margin-left: 10px">张</span>
            </div>
            <div>
              <span>当前活动名额还剩：</span>
              <span class="color1">{{ hasTicketNum }}</span>
            </div>
          </el-form-item>
          <el-form-item label="金额" prop="">
            <div class="row">
              <el-input class="mini-input" v-model="adminUser.price" placeholder="" readonly />
              <span style="margin-left: 10px">元</span>
            </div>
          </el-form-item>
        </div>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitAccountTypeForms">保存</el-button>
        <el-button @click="accountTypeDialogVisibles = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="dialogform" width="70%" class="account_dialog">
      <div class="storeCenter_item">
        <div class="storeCenter_item_top">
          <!-- 卡片左边 -->
          <div class="storeCenter_item_top_left">
            <div class="banner_box">
              <img :src="topDetails.surfaceDrawing" alt="" />
              <!-- v-if="item.activityImg" -->
              <!-- <img v-else src="../../assets/image/Group 3237.png" alt="" /> -->
            </div>
            <div class="storeCenter_item_top_left_flex">
              <div class="title_row">
                <div class="title">
                  {{ topDetails.activityName }}
                </div>
                <div class="title_row_item">
                  <img
                    v-if="topDetails.participationMode === '1'"
                    :src="require('@/assets/image/on-line.png')"
                    alt=""
                  />
                  <img v-else :src="require('@/assets/image/off-line.png')" alt="" />
                  <span class="mode_txt">{{
                    topDetails.participationMode === '1' ? '线上' : '线下'
                  }}</span>
                </div>
                <div class="state_box" :class="getActivityState(topDetails, 'class')">
                  <img
                    v-if="topDetails.activityState === '4'"
                    :src="require('@/assets/image/activity-state01.png')"
                    alt=""
                  />
                  <span>{{ getActivityState(topDetails, 'txt') }}</span>
                </div>
              </div>
              <!-- 活动主题 -->
              <div class="center_row">{{ topDetails.caseSubject }}</div>
              <div class="address">
                <div>
                  <span>所在地区：</span>
                  <span v-if="topDetails.activityProvince">
                    {{ topDetails.activityProvince }}
                    <span>-</span>
                    <span>{{ topDetails.activityCity ? topDetails.activityCity : '暂无' }}</span>
                  </span>
                  <span v-else>暂无</span>
                </div>
                <div v-if="topDetails.activityPhase === '1'">
                  <div v-if="topDetails.activityStartTime">
                    <span>活动时间：</span>
                    <span> {{ topDetails.activityStartTime }}</span>
                    <span> 至 </span>
                    <span>{{ topDetails.activityEndTime }}</span>
                  </div>
                  <div v-else>
                    <span>活动时间：暂无</span>
                  </div>
                </div>
                <div v-else>
                  <span>活动时间：</span>
                  <span>
                    {{
                      topDetails.activityProjectTime ? topDetails.activityProjectTime : '暂无'
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialogVisible">
        <div class="dialogVisible_top">
          <div class="username_phone">
            <span>{{ feedinfo.realName }}</span>
            <span style="margin-left: 10px">{{ feedinfo.phone }}</span>
          </div>
          <div class="username_phone">
            <span>{{ feedinfo.companyFullName }}</span>
            <span class="feed_center"></span>
            <span>{{ feedinfo.city }}</span>
          </div>
        </div>
        <div class="feed_info">
          <div class="feed_info_cont" v-for="(item, index) in formlist" :key="index">
            <div class="caption">
              <span>{{ index + 1 + '、' + item.questionsTitle }}</span>
              <span v-if="item.type === 1">(单选)</span>
              <span v-if="item.type === 3">(多选)</span>
            </div>
            <div class="answer">
              <div v-if="item.type === 2">
                <!-- 附件上传 -->
                <div class="annex_flex" v-for="(el, indexs) in item.fileList" :key="indexs">
                  <i class="el-icon-folder-opened"></i>
                  <span class="annex_name" @click="openPdf(item.answer[indexs])">{{ el }}</span>
                </div>
              </div>
              <div v-else-if="item.type === 7">
                <!-- 搜索选择 -->
                <div v-for="(el, indexs) in item.answer" :key="indexs">
                  <span class="annex_name">{{ el }}</span>
                </div>
              </div>
              <div v-else-if="item.type === 17">
                <!-- 行业回显 -->
                <div style="margin-top: 5px" v-for="(el, indexs) in item.list" :key="indexs">
                  <span class="order_num">{{ indexs + 1 }}</span>
                  <span>{{ el }}</span>
                </div>
              </div>
              <div v-else-if="item.type === 13 && item.isOne">
                <!-- 时间范围 -->
                <span class="annex_name">{{ item.answer[0] }}</span>
                <span class="annex_name" v-if="item.answer.length > 1">{{
                  '~' + item.answer[1]
                }}</span>
              </div>
              <div v-else-if="item.type === 4">
                <!-- 图片上传 -->
                <div v-for="(el, indexs) in item.answer" :key="indexs">
                  <el-image
                    style="width: 80px; height: 80px; border-radius: 4px"
                    :src="el"
                    :preview-src-list="item.answer"
                  ></el-image>
                </div>
              </div>
              <div v-else>
                <!-- 其他问题回显 -->
                <div style="margin-top: 5px" v-for="(el, indexs) in item.answer" :key="indexs">
                  <span class="order_num">{{ indexs + 1 }}</span>
                  <span>{{ el }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_list" style="text-align: center; margin-top: 40px" v-if="dialogType !== 3">
          <div class="left" v-if="dialogType === 1">
            <el-button type="info" v-if="feedinfo.registerStatus == 1">已签到</el-button>
            <el-button type="primary" v-else @click="userSignIn(feedinfo)">签到</el-button>
          </div>
          <div class="left" v-else>
            <el-button type="primary" @click="passClick(feedinfo)">通过</el-button>
            <el-button class="refuse_btn" @click="unPassClick(feedinfo)">不通过</el-button>
          </div>
          <el-button class="cancel_btn" @click="dialogform = false">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAppInfo,
  calculatedAmount,
  selectActivitySignUp,
  refuseUserRegisterActivity,
  auditUserRegisterActivity,
  selectById,
  userSignIn,
  userPushRegister,
  exportExcel,
  selectByActivityId,
  selectIndustry
} from '@/api/activecenter'
import { commerceListTwo } from '@/api/chambercenter.js'
import { userlist } from '@/api/wechatUser.js'
import { accMul } from '@/utils/function.js'
import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  activityId: '', // 活动id
  applyStatus: '0', // 申请状态;全部是传0；2是通过，3是拒绝
  ids: null, //
  pageNum: 1,
  pageSize: 10,
  queryConditions: '', // 查询关键字
  registerStatus: '', // 签到状态;0是未签到，1是已签到
  type: '2', // 活动报名审核状态（1：待审核；2是审核成功；3：已审核;
  sortDirection: 'desc', // 排序方向
  sortType: 'createTime', // 排序字段:已审核默认->审核时间：applyTime；待审核/报名成功默认->申请时间：createTime；支付金额：activePrice
  userLevel: '' // 用户等级;0是普通用户，1是个人会员，2是企业会员
})
const defaultRefuseObj = Object.freeze({
  id: '', //当前用户的id
  refuseReason: '' //拒绝的理由
})
const defaultAdminUser = Object.freeze({
  activeId: null, // 活动ID
  chamberCommerceId: null, // 商会编号
  companyFullName: '', // 商会名称
  companyIndustry: '', // 所在行业
  count: 1, //票数
  gender: 1, // 性别(默认1) 1->男；2->女
  mobile: '', // 手机号
  position: '', // 商会职位
  price: 0, // 价格
  priceId: 0, // 票种id
  ticketName: '', //票种名称
  type: '0', // 用户类型
  userId: null,

  userName: '' // 姓名
})
export default {
  name: 'accountManagement',
  mixins: [pagination],

  data() {
    return {
      dialogType: 1, // 控制详情弹窗的按钮显示
      requestNum: 0, // 请求接口次数
      hasTicketNum: 0, // 剩余票数
      hasTicketNumList: [], // 剩余票数的数组
      activityId: null,
      ids: [],
      rules: {
        refuseReason: [{ required: true, message: '请输入不通过理由', trigger: 'blur' }]
      }, //提交理由的验证表单
      paramrules: {
        monthNum: [{ required: true, message: '请勾选账号周期', trigger: 'change' }]
      },
      dialogVisible: false, //拒绝理由的弹窗
      dialogform: false, //表单弹窗
      feedinfo: {}, //详情弹窗信息
      formlist: [], //表单信息
      open: false, //通过窗口
      refuseObj: {
        id: null,
        refuseReason: null
      },
      // 遮罩层
      loading: true,
      tableData: [], //表格数据
      queryInfo: { ...defaultQueryInfo },
      params: {
        id: null,
        monthNum: null
      },
      topDetails: {}, //头部活动信息
      InformationTypes: [
        {
          value: '全部',
          id: '0'
        },
        {
          value: '已通过',
          id: '2'
        },
        {
          value: '不通过',
          id: '3'
        }
      ],
      //新增报名信息
      adminUser: {
        ...defaultAdminUser
      },
      accountTypeDialogVisibles: false, //新增人员弹窗
      adminrules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        companyFullName: [{ required: true, message: '请输入所在商会', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入企业电话', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern:
              /((13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8})|^(0[0-9]{2,3}-)?([2-9][0-9]{0,9})(-[0-9]{1,9}){0,9}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ]
      },
      ticketList: [], // 票种列表
      industryNameList: [], // 查出的行业列表
      enterpriseNameList: [] //搜索的企业·1数据
    }
  },
  computed: {
    getActivityState() {
      return function (item, type) {
        const state = item.activityState
        if (type === 'txt') {
          if (item.activityPhase === '2') {
            return '规划中'
          } else {
            if (state === '1') {
              return '未开始'
            } else if (state === '2') {
              return '进行中'
            } else if (state === '3') {
              return '已结束'
            } else if (state === '4') {
              return '预热中'
            }
          }
        } else {
          if (item.activityPhase === '2') {
            return 'state_five'
          } else {
            if (state === '1') {
              return 'state_one'
            } else if (state === '2') {
              return 'state_two'
            } else if (state === '3') {
              return 'state_three'
            } else if (state === '4') {
              return 'state_four'
            }
          }
        }
      }
    }
  },
  created() {
    this.activityId = Number(this.$route.query.id)
    this.queryInfo.activityId = this.activityId
    this.getSelectById()
    this.search()
  },
  methods: {
    //活动详情
    async getSelectById() {
      const { data: res } = await selectById({ id: this.activityId })
      if (res.resultCode == 200) {
        this.topDetails = res.data
      }
    },
    // 切换tab
    tabClick(e) {
      this.queryInfo = { ...defaultQueryInfo }
      this.queryInfo.type = e.name
      this.queryInfo.sortType = this.queryInfo.type === '3' ? 'applyTime' : 'createTime'
      this.queryInfo.activityId = this.activityId
      this.search()
    },
    // 输入手机号后查询用户信息
    async phoneChanged(val) {
      const obj = {
        pageNum: 1,
        pageSize: 10,
        param: val
      }
      const { data: res } = await userlist(obj)
      if (res.resultCode === 200 && res.data.list.length > 0) {
        const item = res.data.list[0]
        this.adminUser.userId = item.id
        this.adminUser.position = item.job ? item.job : ''
        this.adminUser.type = item.type ? item.type : ''
      }
    },
    // 选中购票数，计算票价
    getTicketPrice(val, type) {
      if (type === 2) {
        this.ticketList.forEach((el) => {
          // 比较id获取选中的票种名
          if (el.id === val) {
            this.adminUser.ticketName = el.name
          }
        })
        this.hasTicketNumList.forEach((el) => {
          // 比较id获取选中票种的剩余票数
          if (el.id === val) {
            this.hasTicketNum = el.noTicketNumber
          }
        })
      }
      // 调取计算总票价方法
      this.getPrice({
        activityTicketId: this.adminUser.priceId,
        userType: this.adminUser.type,
        voteNumber: this.adminUser.count
      })
    },
    // 获取各票种的剩余票数列表、票种名列表
    async getTicketInfo() {
      const { data: res } = await getAppInfo({ id: this.activityId })
      if (res.resultCode === 200) {
        const list = res.data.activityTickets
        this.hasTicketNum = list[0].noTicketNumber
        this.getPrice({
          activityTicketId: this.adminUser.priceId,
          userType: this.adminUser.type,
          voteNumber: this.adminUser.count
        })
        this.ticketList = list.map((el) => ({
          id: el.id,
          name: el.ticketName
        }))
        this.hasTicketNumList = list.map((el) => ({
          id: el.id,
          noTicketNumber: el.noTicketNumber
        }))
      }
    },
    // 计算总票价
    async getPrice(query) {
      const { data: res } = await calculatedAmount(query)
      if (res.resultCode == 200) {
        this.adminUser.price = res.data
      }
    },
    //公司名输入框变化
    releaseUnitChanged() {
      this.enterpriseNameList = []
    },
    //选择公司
    async enterpriseNameSelect(item) {
      const { data: res } = await commerceListTwo({ chamberCommerceName: item.value })
      if (res.resultCode === 200) {
        const item = res.data[0]
        this.adminUser.companyFullName = item.chamberCommerceName
        this.adminUser.chamberCommerceId = item.id
        this.enterpriseNameList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询公司
    async enterpriseNameQuerySearch(str, cb) {
      if (str != '') {
        const { data: res } = await commerceListTwo({ chamberCommerceName: str })
        if (res.resultCode === 200) {
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              this.enterpriseNameList.push({
                value: item.chamberCommerceName,
                id: item.id
              })
            })
          }
          cb(this.enterpriseNameList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    // 行业输入框变化
    industryChanged() {
      this.industryList = []
    },
    //选择行业
    async industrySelect(item) {
      const { data: res } = await selectIndustry({ industry: item.value })
      if (res.resultCode === 200) {
        this.adminUser.companyIndustry = res.data[0]
        this.industryList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询行业
    async industryQuerySearch(str, cb) {
      if (str != '') {
        const { data: res } = await selectIndustry({ industry: str })
        if (res.resultCode === 200) {
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              this.industryList.push({
                value: item
              })
            })
          }
          cb(this.industryList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    // 重置排序
    resetSort() {},
    // 排序
    changeSort(val) {
      // sortType
      this.queryInfo.sortType = val.prop
      if (val.order === 'ascending') {
        this.queryInfo.sortDirection = 'asc'
      } else {
        this.queryInfo.sortDirection = 'desc'
        if (val.order !== 'descending') {
          // this.resetSort()
          this.queryInfo.sortType = this.queryInfo.type === '3' ? 'applyTime' : 'createTime'
        }
      }

      this.search()
    },
    //新增人员保存
    submitAccountTypeForms() {
      if (this.hasTicketNum > 0) {
        this.adminUser.activeId = this.activityId
        this.$refs['accountTypeDialogs'].validate(async (valid) => {
          if (valid) {
            // 验证通过执行的函数
            if (this.requestNum < 1) {
              // 节流
              const { data: res } = await userPushRegister(this.adminUser)
              this.resetRequestNum()
              if (res.resultCode === 200) {
                this.accountTypeDialogVisibles = false
                this.search()
              } else {
                this.$message.error(res.message)
              }
            }
          }
        })
      } else {
        this.$message.warning('该票种暂无票数，请前往活动详情调整票数~')
      }
    },
    // 定时重置接口请求次数
    resetRequestNum() {
      this.requestNum++
      setTimeout(() => {
        this.requestNum = 0
      }, 2000)
    },
    //批量导出
    async exportExcel() {
      if (this.ids.length > 0) {
        this.loading = true
        const query = {
          activityId: this.activityId,
          ids: this.ids
        }
        const res = await exportExcel(query)
        if (res.data) {
          this.loading = false
          let blob = new Blob([res.data])
          let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          console.log(href)
          a.href = href // 指定下载'链接
          let nowdata = new Date()
          let year = nowdata.getFullYear()
          let month = nowdata.getMonth() + 1
          let date = nowdata.getDate()
          let nowtime = year + '-'
          if (month < 10) {
            nowtime += '0'
          }
          nowtime += month + '-'
          if (date < 10) {
            nowtime += '0'
          }
          nowtime += date
          a.download = `${this.topDetails.activityName}活动报名名单${nowtime}.xlsx` //指定下载文件名
          // a.download = "test.png";
          a.click() //触发下载
          window.URL.revokeObjectURL(a.href) //释放URL对象
          if (document.body.contains(a)) {
            document.body.removeChild(a) //释放标签
          }
          this.search()
          // this.isIndeterminate = false
          // this.checkAll = false
        } else {
          this.loading = false
          this.$message.error('下载失败!')
        }
      } else {
        this.$message.warning('请先选择需要导出的用户~')
      }
    },
    //提交按钮拒绝理由
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { data: res } = await refuseUserRegisterActivity(this.refuseObj)
          if (res.resultCode === 200) {
            this.$message.success('提交成功')
            this.dialogVisible = false
            this.search()
          } else {
            this.$message.error('拒绝失败')
          }
        } else {
          this.$message.error('请输入拒绝理由')
        }
      })
    },
    //新增人员
    clickVisibles() {
      this.accountTypeDialogVisibles = true
      this.adminUser = { ...defaultAdminUser }
      this.adminUser.priceId = this.ticketList[0].id
    },
    //关闭拒绝理由的弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.dialogVisible = false
        })
        .catch((_) => {})
    },
    //通过审核
    passClick(row) {
      let query = {
        id: row.id
      }

      this.$confirm('您是否确认通过？')
        .then(async () => {
          // 需要判断是否还可以报名活动
          const { data: res } = await auditUserRegisterActivity(query)
          if (res.resultCode === 200) {
            this.search()
            this.$message.success('审核通过')
          } else {
            this.$message.error('审核失败')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //查看附件
    openPdf(val) {
      window.open(val)
    },
    //签到
    userSignIn(row) {
      let query = {
        activityId: this.activityId,
        phone: row.phone
      }

      this.$confirm('您是否确认签到？')
        .then(async () => {
          const { data: res } = await userSignIn(query)
          if (res.resultCode === 200) {
            this.search()
            this.dialogform = false
            this.$message.success('签到成功!')
          } else {
            this.$message.error('签到失败!')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //详情弹窗
    async dialoginfo(row, type) {
      this.dialogType = type
      this.dialogform = true
      this.feedinfo = row
      const query = {
        id: this.activityId,
        // phone: row.phone
        userId: row.id
      }
      const { data: res } = await selectByActivityId(query)
      if (res.resultCode === 200) {
        if (res.data && res.data.length) {
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              item.list = []
              item.fileList = []
            })
            this.formlist = res.data
            this.formlist.forEach((el) => {
              if (el.answer && el.answer.length > 0) {
                if (el.type === 2) {
                  if (el.answer && el.answer.length) {
                    el.answer.forEach((o, index) => {
                      var indexs = el.answer[index].lastIndexOf('/')
                      let filename = el.answer[index].substring(indexs + 1, el.answer[index].length)
                      el.fileList.push(filename)
                    })
                  }
                } else if (el.type === 17) {
                  // 行业回显
                  const str = el.answer[0]
                  // console.log( str )
                  const arr = JSON.parse(str)
                  arr.forEach((i1) => {
                    i1.areas.forEach((ii1, ii2) => {
                      if (ii2 !== 0) {
                        // areas第一个值为父级标签
                        el.list.push(ii1)
                      }
                    })
                  })
                }
              }
            })
            // console.log(this.formlist)
          }
        }
      } else {
        this.$message.error(res.message)
      }
    },
    //不通过审核
    unPassClick(row) {
      this.refuseObj.id = row.id
      this.dialogVisible = true
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },

    //保存首页勾选数据
    getRowKey(row) {
      return row.id
    },
    //获取表格数据
    async search() {
      this.loading = true
      const { data: res } = await selectActivitySignUp(this.queryInfo)
      if (res.resultCode === 200) {
        this.getTicketInfo()
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.$message.error(res.message)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.btn_list {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .left {
    display: flex;
    ::v-deep .refuse_btn {
      color: #f53939 !important;
      border-color: #f53939 !important ;
      background-color: #fff;
    }
  }
  .cancel_btn {
    margin-left: 10px;
  }
}
.account_management {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  ::v-deep .el-autocomplete {
    position: relative;
    display: block;
  }
  .state_box {
    display: flex;
    justify-content: center;
    min-width: 50px;
    padding: 4px 10px;
    height: 18px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 18px;
    text-align: center;
    overflow: hidden;
    img {
      width: 18px;
      height: 18px;
    }
  }
  .card {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
  }
  .top_card {
    margin-bottom: 20px;
  }

  .body_card {
    flex: 1;
  }

  /deep/ .el-tabs__item {
    font-size: 20px;
    color: #999999;
  }

  /deep/ .el-tabs__item.is-active {
    color: #4e93fb !important;
  }

  .table_data {
    .success {
      color: #ff7d18;
      font-size: 14px;
      font-weight: 400;
    }

    .error {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      p {
        color: #f53939;
        font-size: 14px;
        font-weight: 400;
      }

      .span {
        color: #999999;
        font-size: 12px;
        display: inline-block;
        width: 100%;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .new_span {
        color: #999999;
        line-height: 1.5em;
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
      }
    }

    .img {
      width: 42px;
      height: 42px;
      display: block;
      margin: 0 auto;
      border-radius: 4px;
    }

    .type_can {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #ff7d18;
        border-radius: 50%;
      }

      span {
        color: #ff7d18;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .type_xuan {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #001aff;
        border-radius: 50%;
      }

      span {
        color: #001aff;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .formal {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(78, 147, 251, 0.1);
      color: #4e93fb;
      border-radius: 2px;
    }

    .tourist {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 125, 24, 0.1);
      color: #ff7d18;
      border-radius: 2px;
    }

    .img_list {
      .new_img {
        width: 70px;
        height: 40px;
        border-radius: 4px;
        margin-right: 10px;
      }
    }

    .btn {
      width: 100%;
      display: flex;
      align-items: center;

      .pass {
        cursor: pointer;
        border: 1px solid #4e93fb;
        color: #4e93fb;
        height: 24px;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        line-height: 24px;
        margin-right: 10px;
        text-align: center;
      }

      .un_pass {
        border: 1px solid #fb6956;
        color: #fb6956;
        height: 24px;
        cursor: pointer;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        width: 50px;
        text-align: center;
        line-height: 24px;
      }
    }
  }

  .search {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .search_flex {
      display: flex;
    }
    .search_flex {
      display: flex;
      align-items: center;
    }
  }

  .search_right {
    width: 500px;

    /deep/ .el-button {
      border: 1px solid #448aff;
      color: #fff;
      border-radius: 0;
      background-color: #448aff;
    }
  }

  .new_page {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin: 20px 0;
  }

  .dialog_btn {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dialogVisible {
  margin-top: 20px;
  padding-left: 20px;
  .dialogVisible_top {
    .username_phone {
      padding-bottom: 10px;
      font-size: 18px;
      color: #212121;
      font-weight: 550;
      .feed_center {
        margin-left: 10px;
        margin-right: 10px;
        position: relative;
        top: 2px;
        display: inline-block;
        width: 0px;
        height: 14.5px;
        border: 2px solid #d4d4d4;
      }
    }
  }
  .feed_info {
    padding-top: 10px;
    .feed_info_cont {
      .caption {
        width: 340px;
        padding: 10px 0px;
        font-size: 18px;
        color: #212121;
      }
      .answer {
        padding: 5px 0px;
        color: #212121;
        .annex_flex {
          display: flex;
          padding: 5px;
          width: 322px;
          border: 1px solid #efefef;
          cursor: pointer;
          .annex_name {
            margin-left: 5px;
            width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .order_num {
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #4e93fb;
          border-radius: 16px;
          color: #fff;
          text-align: center;
          line-height: 16px;
          font-size: 12px;
          margin-right: 10px;
        }
      }
    }
  }
}
.storeCenter_item {
  width: 100%;
  // margin-bottom: 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  .item_checkbox {
    line-height: 36px;
    margin: 0px 10px;
  }
  .storeCenter_item_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    background-color: #ffffff;
    // padding: 16px 20px;
    .storeCenter_item_top_left {
      display: flex;
      align-items: center;
      .banner_box {
        width: 76px;
        height: 76px;
        margin-right: 16px;
        img {
          width: 76px;
          height: 76px;
        }
      }

      .storeCenter_item_top_left_flex {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .title_row {
          display: flex;
          align-items: center;
          line-height: 24px;
          .title {
            font-family: 'PingFang SC';
            font-size: 18px;
            color: #151515;
            margin-right: 18px;
          }
          .title_row_item {
            display: flex;
            align-items: center;
            margin-right: 18px;
            img {
              width: 14px;
              height: 14px;
              margin-right: 8px;
            }
            .mode_txt {
              font-size: 14px;
              color: #6e6e6e;
            }
          }

          .state_one {
            background-color: #f3efeb;
            color: #c2946b;
          }
          .state_two {
            background-color: #fff3e9;
            color: #ff8a21;
          }
          .state_three {
            background-color: #f5f5f5;
            color: #9b9a9a;
          }
          .state_four {
            background-color: #fff3e9;
            color: #ff8a21;
          }
          .state_five {
            background-color: #f9f4f0;
            color: #c2946b;
          }
        }
        .center_row {
          margin: 10px 0;
        }
        .address {
          display: flex;
          font-size: 14px;
          div {
            padding-right: 20px;
          }
        }
      }
    }
  }
}
.flex_input {
  width: 80% !important;
}
</style>
